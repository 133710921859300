import React from 'react';
import Context from './UserContext';

const Provider = ({ children }) => {
  const [user, setUser] = React.useState();

  React.useEffect(() => {
    const stored = window.localStorage.getItem('userAffiliate');
    setUser(stored ? JSON.parse(stored) : {});
  }, []);

  return <Context.Provider value={{ user }}>{children}</Context.Provider>;
};

export default Provider;
