import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import Checkbox from '@material-ui/core/Checkbox';
import { useForm } from "react-hook-form";
import { createAffiliate } from '../api/Affiliate';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://guiapass.com/">
        GuiaPass
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  shortcut: {
    backgroundColor: 'white',
    width: '100%',
    boxShadow: '1px 2px 2px rgba(0,0,0,.2)',

    '& .MuiIcon-root': {
      fontSize: '4em',
    }
  },
}));

export default function Signup() {
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const [error, setError] = React.useState();

  const onSubmit = data => {
    setError(null);
    
    const invitedBy = window.localStorage.getItem('invitedBy');

    const d = {
      invitedById: invitedBy,
      ...data
    }

    if (d.password !== d.passwordConfirmation) {
      setError("Confirmação de senha não confere");
      return;
    }

    if (d.terms !== true) {
      setError("Necessário aceitar os termos");
      return;
    }

    const { fetcher } = createAffiliate(d);

    fetcher.then(result => {
      window.localStorage.setItem('userAffiliate', JSON.stringify(result.createAffiliate));
      window.location.href = '/confirm-email';
    }).catch(err => {
      setError(JSON.stringify(err.response.errors[0].attributes));
    });
  };

  React.useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const urlInvitedById = params.get("i");

    if (urlInvitedById) {
      window.localStorage.setItem('invitedBy', urlInvitedById);
    }
  }, []);

  return (
    <Container component="main" maxWidth="xs" className={classes.main}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <Icon>check_circle</Icon>
        </Avatar>

        <Typography component="h1" variant="h5">
          Cadastro de Afiliado
        </Typography>

        <Typography component="p" variant="body1">
          Solicitação de Afiliação
        </Typography>

        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          { error && <Alert severity="error">{error}</Alert> }

          <TextField
            inputRef={register({ required: true })}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            name="name"
            label="Nome completo"
            autoFocus
          />

          <TextField
            inputRef={register({ required: true })}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            type="email"
            autoComplete="email"
          />

          <TextField
            inputRef={register({ required: true })}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="phone"
            label="Celular (WhatsApp)"
            name="phone"
            type="tel"
          />

          <TextField
            inputRef={register({ required: true })}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="off"
          />

          <TextField
            inputRef={register({ required: true })}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="passwordConfirmation"
            label="Confirmação de senha"
            type="password"
            id="passwordConfirmation"
            autoComplete="off"
          />

          <Typography component="p" variant="body2">
            <Checkbox color="primary" name="terms" inputRef={register()} />
            Li e concordo com os
            {' '}
            <Link color="primary" href="#">
              termos e condições
            </Link>
          </Typography>

          <br />

          <Typography component="h6" variant="h6" gutterBottom>
            Observações
          </Typography>

          <Typography component="p" variant="body2" gutterBottom>
            1. Após enviar esta solicitação, nossa equipe irá entrar em contato com você por WhatsApp
            assim que possível.
          </Typography>

          <Typography component="p" variant="body2">
            2. Sua afiliação está sujeita a análise e aprovação por parte do GuiaPass.
          </Typography>

          <Button
            size="large"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Solicitar afiliação
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
