import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import User from './UserContext';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import { emailAffiliate, confirmEmailAffiliate } from '../api/Affiliate';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormControl-root': {
      margin: theme.spacing(1, 0),
    }
  },
  paper: {
    minWidth: "200px",
    maxWidth: "500px",
    padding: theme.spacing(2),
  },
  space: {
    margin: theme.spacing(2, 0),
  },
  error: {
    marginBottom: theme.spacing(2),
  }
}));

export default function ReportPage() {
  const classes = useStyles();
  const { user } = React.useContext(User);
  const [error, setError] = React.useState();
  const [changeEmail, setChangeEmail] = React.useState(false);
  const inputRef = React.useRef();
  const codeInputRef = React.useRef();
  const [value, setValue] = React.useState('');
  const [valueCode, setValueCode] = React.useState('');
  const [savedEmail, setSavedEmail] = React.useState(false);

  const redirectToPassword = () => {
    if (window.confirm("Você será deslogado e direcionado à tela de alteração de senha. Deseja continuar?")) {
      window.localStorage.removeItem('userAffiliate');
      window.location.href = '/change-password?email=' + user.email;
    }
  };

  const onChangeEmail = () => {
    setChangeEmail(true);
  };

  const cancelChangeEmail = () => {
    setChangeEmail(false);
    setSavedEmail(false);
    setValue(user.email)
    setValueCode(null);
    setError(null);
  };

  const onConfirmEmail = () => {
    setError(null);

    const { fetcher } = emailAffiliate({
      email: value
    });

    const errorMsg = "Verifique se o formato do email está correto ou se já não está sendo utilizado em outra conta.";

    fetcher.then(result => {
      if(result.emailAffiliate) {
        setSavedEmail(true);
      } else {
        setError(errorMsg);
      }
    }).catch(err => {
      setError(errorMsg);
    });
  };

  const onConfirmCode = () => {
    setError(null);

    const { fetcher } = confirmEmailAffiliate({
      email: value,
      code: valueCode
    });

    const errorMsg = "Verifique se o código de validação está correto.";

    fetcher.then(result => {
      if(result.confirmEmailAffiliate.email === value) {
        window.localStorage.setItem('userAffiliate', JSON.stringify(result.confirmEmailAffiliate));
        window.location.href = '/account';  
      } else {
        setError(errorMsg);
      }
    }).catch(err => {
      setError(errorMsg);
    });
  };

  const handleConfirmEmail = () => {
    if (!savedEmail) {
      onConfirmEmail();
    } else {
      onConfirmCode();
    }
  };

  React.useEffect(() => {
    if (changeEmail) {
      setValue("");
      inputRef.current.focus();
    };
  }, [changeEmail]);

  React.useEffect(() => {
    if (savedEmail) {
      codeInputRef.current.focus();
    };
  }, [savedEmail]);

  React.useEffect(() => {
    if (user.email) setValue(user.email);
  }, [user]);

  if (!user) return <></>;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography variant="h4" gutterBottom>
          Minha conta
        </Typography>

        <Typography variant="body1" gutterBottom>
          Alguns dados cadastrais não podem ser alterados por aqui,
          nestes casos, por favor, fale com nosso suporte.
        </Typography>

        <div className={classes.space}></div>

        { error && <Alert severity="error" className={classes.error}>{error}</Alert> }

        <TextField
          inputRef={inputRef}
          id="email"
          label={changeEmail ? "Novo email" : "Email"}
          variant="outlined"
          value={value}
          fullWidth
          type="email"
          disabled={!changeEmail || savedEmail}
          onChange={v => setValue(v.target.value)}
          autoComplete='off'
        />

        {
          savedEmail
          &&
          <TextField
            inputRef={codeInputRef}
            id="code"
            label="Código de verificação"
            variant="outlined"
            fullWidth
            type="number"
            helperText="Informe o código de 6 dígitos enviado para o seu novo email"
            onChange={v => setValueCode(v.target.value)}
          />
        }

        {
          !changeEmail
          ?
          <Button color="primary" onClick={onChangeEmail} size="small">
            Alterar email
          </Button>
          :
          <>
            <Button color="primary" variant="contained" onClick={handleConfirmEmail}>
              Confirmar
            </Button>

            &nbsp;&nbsp;

            <Button color="secondary" onClick={cancelChangeEmail} size="small">
              Cancelar
            </Button>
          </>
        }

        <div className={classes.space}></div>

        <TextField
          label="Senha"
          variant="outlined"
          defaultValue={"●●●●●●●●"}
          fullWidth
          disabled
        />

        <Button color="primary" onClick={redirectToPassword} size="small">
          Alterar senha
        </Button>
      </Paper>
    </div>
  );
}
