import Api from './Api';

export function createAffiliate(params) {
  const exec = `
    mutation createAffiliate(
      $invitedById: ID
      $email: String!
      $password: String!
      $phone: String!
      $name: String!
    ) {
      createAffiliate(
        invitedById: $invitedById
        email: $email
        password: $password
        phone: $phone
        name: $name
      ) {
        id
        token
        email
        emailConfirmed
        name
        phone
      }
    }
  `;

  return {
    fetcher: Api().request(exec, params)
  };
}

export function signinAffiliate(params) {
  const exec = `
    mutation signinAffiliate(
      $email: String!
      $password: String!
    ) {
      signinAffiliate(
        email: $email
        password: $password
      ) {
        id
        token
        email
        emailConfirmed
        name
        phone
      }
    }
  `;

  return {
    fetcher: Api().request(exec, params)
  };
}

export function recoverAffiliate(params) {
  const exec = `
    mutation recoverAffiliate(
      $email: String!
    ) {
      recoverAffiliate(
        email: $email
      )
    }
  `;

  return {
    fetcher: Api().request(exec, params)
  };
}

export function codeAffiliate(params) {
  const exec = `
    query codeAffiliate(
      $email: String!
      $recoveryCode: String!
    ) {
      codeAffiliate(
        email: $email
        recoveryCode: $recoveryCode
      )
    }
  `;

  return {
    fetcher: Api().request(exec, params)
  };
}

export function passwordAffiliate(params) {
  const exec = `
    mutation passwordAffiliate(
      $email: String!
      $recoveryCode: String!
      $password: String!
    ) {
      passwordAffiliate(
        email: $email
        password: $password
        recoveryCode: $recoveryCode
      ) {
        id
        token
        email
        emailConfirmed
        name
        phone
      }
    }
  `;

  return {
    fetcher: Api().request(exec, params)
  };
}

export function emailAffiliate(params) {
  const exec = `
    mutation emailAffiliate(
      $email: String!
    ) {
      emailAffiliate(
        email: $email
      )
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, params)
  };
}

export function confirmEmailAffiliate(params) {
  const exec = `
    mutation confirmEmailAffiliate(
      $email: String!
      $code: String!
    ) {
      confirmEmailAffiliate(
        email: $email
        code: $code
      ) {
        id
        token
        email
        emailConfirmed
        name
        phone
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, params)
  };
}
