import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import { useForm } from "react-hook-form";
import { signinAffiliate } from '../api/Affiliate';
import User from './UserContext';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://guiapass.com/">
        GuiaPass
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  shortcut: {
    backgroundColor: 'white',
    width: '100%',
    boxShadow: '1px 2px 2px rgba(0,0,0,.2)',

    '& .MuiIcon-root': {
      fontSize: '4em',
    }
  },
}));

export default function SignIn() {
  const { user } = React.useContext(User);
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const [error, setError] = React.useState();

  const onSubmit = data => {
    setError(null);
    const { fetcher } = signinAffiliate(data);

    fetcher.then(result => {
      window.localStorage.setItem('userAffiliate', JSON.stringify(result.signinAffiliate));
      window.location.href = '/';
    }).catch(err => {
      setError(err.response.errors[0].message);
    });
  };

  React.useEffect(() => {
    if (user && user.token && !user.emailConfirmed) {
      window.location.href = "/confirm-email";
    }
  }, [user]);

  if (user && user.token) return (
    <div>
      <Typography variant="h4" gutterBottom>
        Olá, Afiliado
      </Typography>

      <Typography variant="body1" gutterBottom>
        Bem-vindo(a) ao seu Painel de Afiliado GuiaPass.
      </Typography>

      <Typography variant="body2" gutterBottom>
        1. Criar coupon<br/>
        2. Enviar notas fiscais<br/>
        3. Atualizar dados de login<br/>
        4. Link de afiliado<br/>
        5. Material de apoio<br/>
        6. Conferir saldo<br/>
        7. Convidar outros afiliados
      </Typography>
    </div>
  );

  return (
    <Container component="main" maxWidth="xs" className={classes.main}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <Icon>lock_outline</Icon>
        </Avatar>
        <Typography component="h1" variant="h5">
          Login do Afiliado
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          { error && <Alert severity="error">{error}</Alert> }

          <TextField
            inputRef={register({ required: true })}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            type="email"
            autoFocus
          />
          <TextField
            inputRef={register({ required: true })}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            size="large"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Entrar
          </Button>

          <Typography variant="body2" color="textSecondary" align="center">
            <Link color="inherit" href="/change-password">
              Esqueci minha senha
            </Link>
          </Typography>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
