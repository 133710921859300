import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import { useForm } from "react-hook-form";
import { recoverAffiliate, codeAffiliate, passwordAffiliate } from '../api/Affiliate';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://guiapass.com/">
        GuiaPass
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  shortcut: {
    backgroundColor: 'white',
    width: '100%',
    boxShadow: '1px 2px 2px rgba(0,0,0,.2)',

    '& .MuiIcon-root': {
      fontSize: '4em',
    }
  },
}));

const RecoverForm = ({ show, onSuccess }) => {
  const classes = useStyles();
  const { register, handleSubmit, setValue } = useForm();
  const [error, setError] = React.useState();

  const onSubmit = data => {
    setError(null);
    const { fetcher } = recoverAffiliate(data);

    fetcher.then(result => {
      if(result.recoverAffiliate) {
        onSuccess(data.email);
      } else {
        setError("Email não encontrado");
      }
    }).catch(err => {
      setError(err.response.errors[0].message);
    });
  };

  React.useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const urlEmail = params.get("email");

    setValue("email", urlEmail);
  }, [setValue]);

  if (!show) return <></>;

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="body1" gutterBottom>
        Para alterar sua senha, comece informando seu email utilizado para login no Painel do Afiliado.
      </Typography>

      { error && <Alert severity="error">{error}</Alert> }

      <TextField
        inputRef={register({ required: true })}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label="Email"
        name="email"
        autoComplete="email"
        autoFocus
      />

      <Button
        size="large"
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
      >
        Continuar
      </Button>

      <Typography variant="body2" color="textSecondary" align="center" gutterBottom>
        <Link color="inherit" href="/">
          Voltar ao login
        </Link>
      </Typography>

      <br/>

      <Typography variant="body2" gutterBottom>
        Se você não lembra o seu email ou não possui mais acesso ao mesmo,
        por favor, fale com nosso suporte pelos canais de atendimento no site
        guiapass.com.
      </Typography>
    </form>
  )
};

const CodeForm = ({ show, email, onSuccess }) => {
  const classes = useStyles();
  const [error, setError] = React.useState();
  const { register, handleSubmit } = useForm();

  const onSubmit = data => {
    setError(null);
    const { fetcher } = codeAffiliate(data);

    fetcher.then(result => {
      if(result.codeAffiliate) {
        onSuccess(data.recoveryCode);
      } else {
        setError("Código inválido");
      }
    }).catch(err => {
      setError(err.response.errors[0].message);
    });
  };

  if (!show) return <></>;

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="body1" gutterBottom>
        Agora digite o código de verificação que você recebeu por email.
      </Typography>

      { error && <Alert severity="error">{error}</Alert> }

      <TextField
        inputRef={register({ required: true })}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email"
        name="email"
        defaultValue={email}
        InputProps={{
          readOnly: true,
        }}
      />

      <TextField
        inputRef={register({ required: true })}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="recoveryCode"
        label="Código de verificação"
        name="recoveryCode"
        autoComplete="off"
        type="number"
        autoFocus
      />

      <Button
        size="large"
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
      >
        Continuar
      </Button>
    </form>
  )
};

const PasswordForm = ({ show, email, code }) => {
  const classes = useStyles();
  const [error, setError] = React.useState();
  const { register, handleSubmit } = useForm();

  const onSubmitPassword = data => {
    setError(null);

    const d = {
      email: email,
      recoveryCode: code,
      ...data
    }

    if (d.password !== d.passwordConfirmation) {
      setError("Confirmação de senha incorreta");
    } else {
      const { fetcher } = passwordAffiliate(d);

      fetcher.then(result => {
        if(result.passwordAffiliate.token) {
          window.localStorage.setItem('userAffiliate', JSON.stringify(result.passwordAffiliate));
          window.location.href = '/';
        } else {
          setError("Erro ao salvar senha, tente novamente");
        }
      }).catch(err => {
        setError(err.response.errors[0].message);
      });
    }
  };

  if (!show) return <></>;

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmitPassword)}>
      <Typography variant="body1" gutterBottom>
        Crie sua nova senha.
      </Typography>

      { error && <Alert severity="error">{error}</Alert> }

      <TextField
        inputRef={register({ required: true })}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="password"
        label="Senha"
        name="password"
        autoComplete="off"
        autoFocus
        type="password"
      />

      <TextField
        inputRef={register({ required: true })}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="passwordConfirmation"
        label="Confirme a senha"
        name="passwordConfirmation"
        autoComplete="off"
        type="password"
      />

      <Button
        size="large"
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
      >
        Salvar nova senha
      </Button>
    </form>
  )
};

export default function ForgotPassword() {
  const classes = useStyles();
  const [code, setCode] = React.useState();
  const [email, setEmail] = React.useState();

  return (
    <Container component="main" maxWidth="xs" className={classes.main}>
      <CssBaseline />

      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <Icon>lock_outline</Icon>
        </Avatar>

        <Typography component="h1" variant="h5" gutterBottom>
          Recuperar ou alterar senha
        </Typography>

        <RecoverForm
          show={!email}
          onSuccess={email => setEmail(email)}
        />

        <CodeForm
          show={email && !code}
          email={email}
          onSuccess={code => setCode(code)}
        />

        <PasswordForm
          show={!!code}
          email={email}
          code={code}
        />
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
